import React from 'react'
import GetContainerPagination from '../../../components/get-container-pagination'
import { useParams } from 'react-router-dom'
import { ILesson } from '../../../types/types'
import { useTranslation } from 'react-i18next'

export const LessonsSingle = () => {
  const params = useParams()
  const { i18n, t } = useTranslation()
  return (
    <GetContainerPagination url='teacher/lessons' params={{ subject_id: params?.subject_id }}>
      {({ data }) => (
        <>
          <ul className='breadcrumbs'>
            <li>
              <a href='/teacher/home'>
                <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002ZM12.75 18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18Z'
                    fill='#1F4F51'
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href='/teacher/lessons'>{t('Lessons plans')}</a>
            </li>
            <li>
              <a href={'/teacher/lessons/' + params?.subject_id} className='active'>
                {t('Subject')}: {data?.data?.[0]?.unit?.grade?.subject?.name?.[i18n?.language]}
              </a>
            </li>
          </ul>
          <div className='lessons-single'>
            <div className='lessons-single__head'>
              {/* <div className='lessons-single__title'>
                PROGRAM
                <span>ELA Advanced</span>
              </div> */}
              <div className='lessons-single__title'>
                {t('UNIT')}
                <span>{data?.data?.[0]?.unit?.name?.[i18n?.language]}</span>
              </div>
            </div>

            <div className='history-table'>
              <div className='history-table__head'>
                <div></div>
                <div>{t('N0')}</div>
                <div>{t('Title')}</div>
                <div>{t('Unit')}</div>
                <div>{t('Gradle')}</div>
                <div>{t('Duration')}</div>

                <div>{t('Active')}</div>
                <div></div>
              </div>
              {data?.data?.map((lesson: ILesson, index: number) => (
                <div className='history-table__row' key={lesson?.slug}>
                  <div className='history-table__item'></div>
                  <div className='history-table__item'>{index + 1}</div>
                  <div className='history-table__item'>{lesson?.name?.[i18n?.language]}</div>
                  <div className='history-table__item'>{lesson?.unit?.name?.[i18n?.language]}</div>
                  <div className='history-table__item'>{lesson?.unit?.grade?.name?.[i18n?.language]}</div>
                  <div className='history-table__item'>{lesson?.duration}</div>
                  <div className='history-table__item'>{lesson?.active ? t('yes') : t('No')}</div>
                  <div className='history-table__item' style={{ width: 300 }}>
                    <a
                      href={`/teacher/lessons/${params?.subject_id}/${lesson?.slug}?type=plan`}
                      className='btn btn-green'
                    >
                      {t('Lesson plan')}
                    </a>
                    <a
                      href={`/teacher/lessons/${params?.subject_id}/${lesson?.slug}?type=homework`}
                      className='btn btn-green'
                    >
                      {t('Homework')}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </GetContainerPagination>
  )
}
