import React from 'react'
import { ITariff } from '../../../../types/types'
import { useTranslation } from 'react-i18next'

interface IProps {
  tariff: ITariff
}

function TariffCard(props: IProps) {
  const { i18n, t } = useTranslation()
  return (
    <li className={`rates-item rates-item__${props?.tariff?.name?.['en'].toLowerCase()}`}>
      <div className='rates-item__name'>{props?.tariff?.name?.[i18n.language]}</div>
      <div className='rates-item__coin'>
        <span>{props.tariff?.coin}</span> {t('Coin')}
      </div>
      <div className='rates-item__info'>
        {props.tariff?.options?.map(item => (
          <div>{item?.name?.[i18n?.language]}</div>
        ))}
      </div>
      <div className='rates-item__price'>
        <span>{props.tariff?.sum}</span> {t('Sum')}
      </div>
      <a href='#' className='btn btn-lightgreen'>
        {t('Buy')}
      </a>
    </li>
  )
}

export default TariffCard
