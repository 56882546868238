import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  value: string
  onChange: (value: string) => void
}

function Choose(props: IProps) {
  const { t } = useTranslation()
  return (
    <div className='reviews-add__choose'>
      <label htmlFor={'1'} className='reviews-add__item'>
        <input
          type='radio'
          name='mark'
          id={'1'}
          checked={props.value === '1'}
          onChange={e => {
            if (e.target.checked) {
              props.onChange('1')
            }
          }}
        />
        <div className='reviews-add__item-wrap'>
          <svg width={44} height={44} viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M33.6672 10.3327C40.1109 16.7764 40.1109 27.2236 33.6672 33.6672C27.2236 40.1109 16.7764 40.1109 10.3327 33.6672C3.88909 27.2236 3.88909 16.7764 10.3327 10.3327C16.7764 3.88909 27.2236 3.88909 33.6672 10.3327'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M15.5846 16.5V18.3333'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M28.4167 16.5V18.3333'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M15.582 29.335C15.582 29.335 17.9892 26.9297 21.9987 26.9297C26.01 26.9297 28.4154 29.335 28.4154 29.335'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <div className='reviews-add__item-name'>{t('Very dissatified')}</div>
          <div className='mark'>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </div>
        </div>
      </label>
      <label htmlFor={'2'} className='reviews-add__item'>
        <input
          type='radio'
          name='mark'
          id={'2'}
          checked={props.value === '2'}
          onChange={e => {
            if (e.target.checked) {
              props.onChange('2')
            }
          }}
        />
        <div className='reviews-add__item-wrap'>
          <svg width={44} height={44} viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M16.1147 30.9523C16.1147 30.9523 17.8173 28.0059 21.6902 26.9682C25.5649 25.9299 28.5108 27.6308 28.5108 27.6308'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M33.6672 10.3327C40.1109 16.7764 40.1109 27.2236 33.6672 33.6672C27.2236 40.1109 16.7764 40.1109 10.3327 33.6672C3.88909 27.2236 3.88909 16.7764 10.3327 10.3327C16.7764 3.88909 27.2236 3.88909 33.6672 10.3327'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M15.5846 16.5V18.3333'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M28.4167 16.5V18.3333'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <div className='reviews-add__item-name'>{t('Dissatisfied')}</div>
          <div className='mark'>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </div>
        </div>
      </label>
      <label htmlFor={'3'} className='reviews-add__item'>
        <input
          type='radio'
          name='mark'
          id={'3'}
          checked={props.value === '3'}
          onChange={e => {
            if (e.target.checked) {
              props.onChange('3')
            }
          }}
        />
        <div className='reviews-add__item-wrap'>
          <svg width={44} height={44} viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M33.6672 10.3327C40.1109 16.7764 40.1109 27.2236 33.6672 33.6672C27.2236 40.1109 16.7764 40.1109 10.3327 33.6672C3.88909 27.2236 3.88909 16.7764 10.3327 10.3327C16.7764 3.88909 27.2236 3.88909 33.6672 10.3327'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M15.5846 16.5V18.3333'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M28.4167 16.5V18.3333'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path d='M14 26.5H30' stroke='#83878F' strokeWidth='1.5' strokeLinecap='round' />
          </svg>
          <div className='reviews-add__item-name'>{t('Neutral')}</div>
          <div className='mark'>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </div>
        </div>
      </label>
      <label htmlFor={'4'} className='reviews-add__item'>
        <input
          type='radio'
          name='mark'
          id={'4'}
          checked={props.value === '4'}
          onChange={e => {
            if (e.target.checked) {
              props.onChange('4')
            }
          }}
        />
        <div className='reviews-add__item-wrap'>
          <svg width={44} height={44} viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M33.6672 10.3327C40.1109 16.7764 40.1109 27.2236 33.6672 33.6672C27.2236 40.1109 16.7764 40.1109 10.3327 33.6672C3.88909 27.2236 3.88909 16.7764 10.3327 10.3327C16.7764 3.88909 27.2236 3.88909 33.6672 10.3327'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M28.4154 26.9297C28.4154 26.9297 26.0082 29.335 21.9987 29.335C17.9874 29.335 15.582 26.9297 15.582 26.9297'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M15.5846 16.5V18.3333'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M28.4167 16.5V18.3333'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <div className='reviews-add__item-name'>{t('Satisfied')}</div>
          <div className='mark'>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </div>
        </div>
      </label>
      <label htmlFor={'5'} className='reviews-add__item'>
        <input
          type='radio'
          name='mark'
          id={'5'}
          checked={props.value === '5'}
          onChange={e => {
            if (e.target.checked) {
              props.onChange('5')
            }
          }}
        />
        <div className='reviews-add__item-wrap'>
          <svg width={44} height={44} viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M33.6672 10.3327C40.1109 16.7764 40.1109 27.2236 33.6672 33.6672C27.2236 40.1109 16.7764 40.1109 10.3327 33.6672C3.88909 27.2236 3.88909 16.7764 10.3327 10.3327C16.7764 3.88909 27.2236 3.88909 33.6672 10.3327'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M22 31.168C25.0562 31.168 27.5 28.7241 27.5 25.668H16.5C16.5 28.7241 18.9438 31.168 22 31.168V31.168Z'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M15.5846 16.5V18.3333'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M28.4167 16.5V18.3333'
              stroke='#83878F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <div className='reviews-add__item-name'>{t('Very satisfied')}</div>
          <div className='mark'>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </div>
        </div>
      </label>
    </div>
  )
}

export default Choose
