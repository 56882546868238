import React, { useContext, useState } from 'react'
import GetContainer from '../../../../../components/get-container'
import { ISlot } from '../../../../../types/types'
import { useTranslation } from 'react-i18next'
import { RandomContext } from '../../../../../providers/RandomProvider'
import { useCustomMutation } from '../../../../../hooks/useCustomMutation'
import RequestModal from '../../../../../components/request-modal'
import toast from 'react-hot-toast'
import { isAfterDate } from '../../../../../configs/helpers'
import { AuthContext } from '../../../../../context/AuthContext'

interface IProps {
  id: string
}

const LessonInfo = (props: IProps) => {
  const { i18n, t } = useTranslation()
  const { mutate: cancel, error, isSuccess } = useCustomMutation('/student/slots/cancel', 'POST')
  const [open, setOpen] = useState(false)
  const { setRandom } = useContext(RandomContext)
  const { refresh } = useContext(AuthContext)

  return (
    <GetContainer url={'student/slots/' + props.id}>
      {({ data }: { data: { data: ISlot } }) => (
        <div className='moreLessonItem'>
          <div className='moreLessonItem-title'>
            <div className='lessonName'>{data?.data?.date}</div>
            <div className='lessonTime'>{data?.data?.time}</div>
          </div>
          <div className='content show'>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Course')}: {data?.data?.schedule?.lesson?.unit?.grade?.subject?.name?.[i18n.language]}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Lesson')}: {data?.data?.schedule?.lesson?.name?.[i18n.language]}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Lesson type')}: {data?.data?.schedule?.group?.type}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Status')}: {t(data?.data?.schedule?.main_status)}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Teacher')}:{' '}
                {`${data?.data?.schedule?.group?.teacher?.firstname} ${data?.data?.schedule?.group?.teacher?.lastname}`}
              </div>
            </div>
            {!!data?.data?.schedule?.zoom_link && (
              <div className='contentLesson'>
                <div className='contentLesson-title'>
                  <a href={data?.data?.schedule?.zoom_link}>{t('Zoom link')}</a>
                </div>
              </div>
            )}
          </div>
          <div className='content show' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {isAfterDate(`${data?.data?.date}T${data?.data?.time}`) && (
              <button className='btn btn-transparent' onClick={() => setOpen(true)}>
                {t('Cancel')}
              </button>
            )}
          </div>
          <RequestModal
            isOpen={open}
            close={() => setOpen(false)}
            title='Cancel lesson title'
            error={error}
            isSuccess={isSuccess}
            description={<p>{`${t('Date time')}: ${data?.data?.date} ${data?.data?.time}`}</p>}
            send={e => {
              e.stopPropagation()
              cancel(
                {
                  schedule_id: data?.data?.schedule_id
                },
                {
                  onSuccess(data, variables, context) {
                    refresh()
                    setRandom(Math.random())
                    toast.success('Cancel lesson success title')
                  },
                  onError: (error: any) => toast.error(error?.response?.data?.message || error?.message)
                }
              )
            }}
            acceptBtnText='Yes, I will'
            cancelBtnText='No'
            successTitle='Cancel lesson success title'
            successDesc='Cancel lesson success desc'
            errorTitle='Cancel lesson success title'
          />
        </div>
      )}
    </GetContainer>
  )
}

export default LessonInfo
