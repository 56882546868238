import React from 'react'
import { ITeacher } from '../../../../../types/types'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL } from '../../../../../configs/request'
import TeacherCalendar from '../teacher-calendar'
import { useNavigate } from 'react-router-dom'

interface IProps {
  item: ITeacher
}

const TeachersEach = (props: IProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  return (
    <li className='teachers-item'>
      <div className='teachers-item__wrap'>
        <div className='teachers-item__img'>
          <img
            src={
              props.item?.photo ? MEDIA_URL + props?.item?.photo : require('../../../../../assets/img/mock/teacher.jpg')
            }
            alt='teacher'
          />
        </div>
        <a href={`/student/school/teachers/` + props.item?.uuid} className='teachers-item__btn btn btn-lightblue'>
          {t('View profile')}
        </a>
      </div>
      <div className='teachers-item__wrap'>
        <div className='teachers-item__name'>{`${props.item?.firstname} ${props.item?.lastname}`}</div>
        <div className='teachers-item__pos'>{t(props.item?.role)}</div>
        <div className='teachers-item__desc'>{props.item?.info?.bio}</div>
        <div className='teachers-item__courses'>
          {props?.item?.subjects?.map(subject => (
            <div className='course-type' key={subject?.slug}>
              <span className='course-type__ico'>
                <img src={MEDIA_URL + subject?.icon} alt={subject?.name?.[i18n?.language]} width={'80%'} />
                {/* <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M6.375 7.87477H11.625M5.25 13.8223H8.25L11.5875 16.0423C11.7001 16.1173 11.831 16.1604 11.9662 16.167C12.1015 16.1735 12.2359 16.1432 12.3552 16.0793C12.4746 16.0154 12.5743 15.9203 12.6438 15.8041C12.7134 15.688 12.7501 15.5551 12.75 15.4198V13.8223C15 13.8223 16.5 12.3223 16.5 10.0723V5.57227C16.5 3.32227 15 1.82227 12.75 1.82227H5.25C3 1.82227 1.5 3.32227 1.5 5.57227V10.0723C1.5 12.3223 3 13.8223 5.25 13.8223V13.8223Z'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    strokeMiterlimit={10}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg> */}
              </span>
              <span>{subject?.name?.[i18n?.language]}</span>
            </div>
          ))}
        </div>
      </div>
      <div className='teachers-item__wrap' onClick={() => navigate(`/student/school/teachers/` + props.item?.uuid)}>
        <TeacherCalendar teacherId={props.item?.uuid} />
      </div>
    </li>
  )
}

export default TeachersEach
