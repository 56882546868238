import React, { useContext } from 'react'
import Balance from '../../../../assets/img/icons/Balanse'
import { AuthContext } from '../../../../context/AuthContext'
import { MEDIA_URL } from '../../../../configs/request'
import { useTranslation } from 'react-i18next'

function ProfileInfo() {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  return (
    <div className='profile-info'>
      <div className='profile-info__wrap'>
        <div className='profile-info__photo'>
          {user?.photo ? (
            <img src={MEDIA_URL + user?.photo} alt={user?.firstname} />
          ) : (
            <img src={require('../../../../assets/img/mock/student.jpg')} alt='student' />
          )}
        </div>
        <div className='profile-info__name'>
          <div>{user?.firstname}</div>
          <span>{t(user?.role || 'Profile')}</span>
        </div>
      </div>
      {user?.role !== 'teacher' && (
        <div className='profile-info__balance'>
          <Balance />
          <span>
            {t('Coin')} ({user?.wallet?.amount})
          </span>
        </div>
      )}
    </div>
  )
}

export default ProfileInfo
