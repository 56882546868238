import React from 'react'
import { ITeacher } from '../../../../types/types'
import { useNavigate } from 'react-router-dom'

interface IProps {
  teacher: ITeacher
}

function Head(props: IProps) {
  const navigate = useNavigate()
  return (
    <div className='reviews-single__head'>
      <span onClick={() => navigate(-1)} className='reviews-single__back'>
        <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M9.57 18.0703L3.5 12.0003L9.57 5.93031M20.5 12.0003L3.67 12.0003'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeMiterlimit={10}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </span>
      <div className='reviews-single__name'>{props.teacher?.firstname + ' ' + props.teacher?.lastname}</div>
    </div>
  )
}

export default Head
