import React from 'react'
import SwiperCore from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import GetContainer from '../../../../../components/get-container'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ISlot } from '../../../../../types/types'
import NearbyLessonCard from './NearbyLessonCard'

SwiperCore.use([Navigation, Pagination, Autoplay])

interface YaqinSliderProps {
  onClick: () => void
}

export const YaqinSlider: React.FC<YaqinSliderProps> = ({ onClick }) => {
  const { t, i18n } = useTranslation()
  const swiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    navigation: {
      nextEl: '.yaqin-right',
      prevEl: '.yaqin-left'
    },
    // autoplay: {
    //   delay: 20000, // автоматическая смена слайдов каждые 5 секунд
    // },
    breakpoints: {
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        freeMode: false
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 10,
        freeMode: false
      }
    }
  }

  return (
    <GetContainer
      url={'/student/slots'}
      params={{ status: 'ready_for_lesson,lesson_is_underway', date: moment().format('YYYY-MM-DD') }}
    >
      {({ data }) =>
        !!data?.data?.length && (
          <div className='home-recent'>
            <div className='home-recent__head'>
              <div className='home-recent__title'>{t('Nearby lessons')}</div>
              <div className='home-recent__wrap'>
                {/* <a href='#' className='btn btn-trans'>
            Ko'proq ko'rsatish
          </a> */}
                <div className='arrows'>
                  <span className='arrow-left yaqin-left'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M14 8L10 12L14 16'
                        stroke='currentColor'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </span>
                  <span className='arrow-right yaqin-right'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M10 16L14 12L10 8'
                        stroke='currentColor'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            {/* Swiper */}

            <Swiper {...swiperOptions} className='home-recent__carousel'>
              {data?.data?.map((item: ISlot) => (
                <SwiperSlide className='lesson' key={item.id}>
                  <NearbyLessonCard data={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )
      }
    </GetContainer>
  )
}
