import Form from '../../../components/form'
import CustomInput from '../../../components/form/custom-input'
import CustomDateInput from '../../../components/form/custom-date-input'
import CustomRadio from '../../../components/form/cutom-radio'
import CustomPasswordInput from '../../../components/form/custom-password-input'
import CustomCheckbox from '../../../components/form/custom-checkbox'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import auth from '../../../configs/auth'
import * as Yup from 'yup'
const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('This field is required'),
  lastname: Yup.string().required('This field is required'),
  birthday: Yup.string().required('This field is required'),
  email: Yup.string().email().required('This field is required'),
  phone: Yup.string().required('This field is required'),
  password: Yup.string().required('Password is required').min(6, 'Password length should be at least 6 characters'),
  password_confirmation: Yup.string()
    .required('Confirm Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
  accept1: Yup.boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
  accept2: Yup.boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.')
})
const initialValues = {
  firstname: '',
  lastname: '',
  phone: '',
  birthday: new Date(),
  email: '',
  password: '',
  password_confirmation: '',
  referral: '',
  accept1: false,
  accept2: false
}

export const Login = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setUser } = useContext(AuthContext)
  return (
    <>
      {/*  LOGIN	*/}
      <section className='login'>
        <div className='login__wrap'>
          <div className='login__logo'>
            <img src={require('../../../assets/img/logo.png')} alt='Onschool' />
          </div>
          <Form
            url='auth/register'
            initialValues={initialValues}
            validationSchema={validationSchema}
            method='post'
            onSuccess={async response => {
              await localStorage.setItem(auth.storageTokenKeyName, response.data.access_token)
              setUser({ ...response.data.user })
              await window.localStorage.setItem('userData', JSON.stringify(response.data.user))
              await navigate('/code')
            }}
          >
            {({ form, handleFinish }) => {
              const {
                control,
                formState: { errors },
                handleSubmit,
                setValue,
                watch
              } = form
              return (
                <div className='login-form'>
                  <div className='login-form__title'>{t('Register label')}</div>
                  <CustomInput
                    control={control}
                    name='firstname'
                    label='First name'
                    placeholder='Write first name'
                    error={errors?.firstname}
                  />
                  <CustomInput
                    control={control}
                    name='lastname'
                    label='Last name'
                    placeholder='Write last name'
                    error={errors?.lastname}
                  />
                  <CustomDateInput control={control} name='birthday' label='Birthday label' error={errors?.birthday} />
                  <CustomRadio
                    control={control}
                    name='gender'
                    data={[
                      { label: 'Male', value: 'male' },
                      { label: 'Female', value: 'female' }
                    ]}
                    label='Your gender'
                    error={errors?.gender}
                  />
                  <CustomInput
                    control={control}
                    name='phone'
                    label='Phone number'
                    placeholder='Write phone number'
                    error={errors?.phone}
                  />
                  <CustomInput
                    control={control}
                    name='email'
                    label='Email number'
                    placeholder='Write email number'
                    error={errors?.email}
                  />
                  <CustomPasswordInput
                    control={control}
                    name='password'
                    label='Password'
                    placeholder='Write password'
                    error={errors?.password}
                  />
                  <CustomPasswordInput
                    control={control}
                    name='password_confirmation'
                    label='Password confirmation'
                    placeholder='Write password confirmation'
                    error={errors?.password_confirmation}
                  />
                  <CustomInput
                    control={control}
                    name='use_referral_code'
                    label='Referral code'
                    placeholder='Write referral number'
                    error={errors?.use_referral_code}
                  />
                  <CustomCheckbox control={control} name='accept1' label='Accept1' error={errors?.accept1} />
                  <CustomCheckbox control={control} name='accept2' label='Accept2' error={errors?.accept2} />

                  <div className='login-form__submit'>
                    <button className='btn btn-orange' onClick={handleSubmit(data => handleFinish(data))}>
                      {t('Register submit')}
                    </button>
                  </div>
                </div>
              )
            }}
          </Form>
        </div>
      </section>
    </>
  )
}
