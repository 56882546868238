import { useTranslation } from 'react-i18next'
import GetContainer from '../../../components/get-container'
import { ICategory, ISubject } from '../../../types/types'
import Form from '../../../components/form'
import * as Yup from 'yup'
import { Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import RequestModal from '../../../components/request-modal'
import { useState } from 'react'
const validationSchema = Yup.object().shape({})

export const NewCourses = () => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  return (
    <div className='courses-new'>
      <div className='courses-new__title'>{t('New course title')}</div>
      <div className='courses-new__text'>{t('New course description')}</div>
      <GetContainer url='/student/categories' params={{ type: 'individual' }}>
        {({ data }) =>
          data?.data?.map((category: ICategory) => (
            <>
              <div className='courses-new__subtitle'>
                {t('Course')}: {category.name?.[i18n?.language]}
              </div>
              <div className='table courses-new__table'>
                <table>
                  <tbody>
                    <tr className='head'>
                      <td>{t('Name')}</td>
                      <td>{t('Level')}</td>
                      <td>{t('Language')}</td>
                      <td>{t('Sub title')}</td>
                      <td>{t('Coins')}</td>
                    </tr>
                    {category?.subjects?.map(subject => (
                      <tr>
                        <td>{subject?.name?.[i18n.language]}</td>
                        <td>{subject?.level}</td>
                        <td>{subject?.language?.[i18n.language]}</td>
                        <td>{subject?.title?.[i18n?.language]}</td>
                        <td>{subject?.options?.map(option => option?.price)?.join('/')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ))
        }
      </GetContainer>

      <div className='courses-new__subtitle'>{t('The courses you want')}</div>
      <Form
        validationSchema={validationSchema}
        initialValues={{ subject_ids: [] }}
        onSuccess={() => setOpen(true)}
        url='/student/subjects/request'
        method='POST'
      >
        {({ form, handleFinish, createInfo }) => (
          <div className='courses-new__form'>
            <div className='courses-new__checks'>
              <GetContainer url='/student/subjects' params={{ type: 'individual' }}>
                {({ data }) => (
                  <>
                    {data?.data?.map((subject: ISubject) => (
                      <Controller
                        control={form.control}
                        name='subject_ids'
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <label htmlFor={subject?.slug}>
                            <input
                              type='checkbox'
                              checked={value?.some((item: number) => item === subject?.id)}
                              name='subject_ids'
                              onChange={e => {
                                if (e.target?.checked) {
                                  onChange([...value, subject?.id])
                                } else {
                                  onChange(value?.filter((item: number) => item !== subject?.id))
                                }
                              }}
                              id={subject?.slug}
                            />
                            <span>{subject?.name?.[i18n.language]}</span>
                          </label>
                        )}
                      />
                    ))}
                  </>
                )}
              </GetContainer>
            </div>
            <div className='courses-new__buttons'>
              <button className='btn btn-orange' onClick={form.handleSubmit(data => handleFinish(data))}>
                {t('Send')}
              </button>
              <button className='btn btn-transparent' onClick={() => form?.setValue('subject_ids', [])}>
                {t('Clear')}
              </button>
            </div>
          </div>
        )}
      </Form>
      <RequestModal
        isOpen={open}
        close={() => setOpen(false)}
        title='Request subjects title'
        error={null}
        isSuccess={true}
        description={
          <>
            {/* <p>
                    {data?.data
                      ?.filter((subject: ISubject) =>
                        form.watch('subject_ids')?.some((id: number) => id === subject?.id)
                      )
                      ?.map((subject: ISubject) => subject?.name?.[i18n.language])
                      .join(', ')}
                  </p> */}
          </>
        }
        send={() => {}}
        acceptBtnText='Yes, I will'
        cancelBtnText='No'
        successTitle='Request subjects success title'
        successDesc='Request subjects success desc'
        errorTitle='Request subjects success title'
      />
    </div>
  )
}
