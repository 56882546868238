import React from 'react'
import { ICount } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL } from '../../../../configs/request'

interface IProps {
  item: ICount
}

function ReviewCard({ item }: IProps) {
  const { t } = useTranslation()
  return (
    <div className='reviews-list__item'>
      <div className='reviews-list__wrap'>
        <div className='course-type'>
          <span className='course-type__ico'>
            <img src={MEDIA_URL + item?.subject?.icon} alt='icon' />
          </span>
          <span>{item?.subject?.name?.['ru']}</span>
        </div>
        <div className='reviews-list__count'>
          {item.count} {t('review')}
        </div>
      </div>
      {/* <div className='reviews-list__teacher'>
        <div>{item?.teacher?.firstname + ' ' + item?.teacher?.lastname}</div>
        <img
          src={
            item?.teacher?.photo ? MEDIA_URL + item?.teacher?.photo : require('../../../../assets/img/mock/student.jpg')
          }
          alt='student'
        />
      </div> */}
      <a href={'/student/home/teacher-reviews/' + item?.subject?.id} className='reviews-list__link' />
    </div>
  )
}

export default ReviewCard
