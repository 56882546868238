import { useState } from 'react'
import GetContainer from '../../../components/get-container'
import TeachersEach from './component/teachers-each'
import TeachersFilter from './component/teachers-filter'
import TeachersWrapper from './component/teachers-wrapper'
import moment from 'moment'
import { ISubject, ITeacher } from '../../../types/types'
import GetContainerPagination from '../../../components/get-container-pagination'

export const Teachers = () => {
  const [filter, setFilter] = useState({
    subject_ids: [],
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().endOf('month').format('YYYY-MM-DD'),
    start_time: '07:00',
    end_time: '18:00',
    day_of_week: [1, 2, 3, 4, 5, 6, 7],
    gender: 'male',
    search: '',
    foreigner: false
  })
  return (
    <>
      <GetContainer
        hideLoading
        url='/student/subjects/self'
        onSuccess={async data => setFilter({ ...filter, subject_ids: data?.data?.map((item: ISubject) => item?.id) })}
      >
        {({ data }) => <TeachersFilter selfSubjects={data?.data} value={filter} setValue={setFilter} />}
      </GetContainer>
      <GetContainerPagination
        url={!!filter?.subject_ids?.length ? '/student/teachers' : ''}
        params={{
          ...filter,
          day_of_week: filter?.day_of_week?.join(','),
          subject_ids: filter?.subject_ids?.join(','),
          end_date: moment(filter?.end_date).format('YYYY-MM-DD'),
          start_date: moment(filter?.start_date).format('YYYY-MM-DD'),
          foreigner: +filter?.foreigner
        }}
      >
        {({ data }) => (
          <TeachersWrapper>
            {data?.data?.map((teacher: ITeacher) => (
              <TeachersEach item={teacher} />
            ))}
          </TeachersWrapper>
        )}
      </GetContainerPagination>
    </>
  )
}
