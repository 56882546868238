import { useEffect, useState } from 'react'
import { getMessaging, getToken } from 'firebase/messaging'
import { useMutation } from '@tanstack/react-query'
import { API_URL, request } from '../configs/request'
import { firebaseApp } from '../configs/firebase'

export const SEND_TOKEN = async (data: any) => {
  const response = await request({
    url: API_URL + 'firebase_token',
    method: 'PUT',
    data: data
  })

  return response.data
}

const useFcmToken = () => {
  const [token, setToken] = useState('')
  const [notificationPermissionStatus, setNotificationPermissionStatus] = useState('')

  const { mutate } = useMutation({ mutationFn: SEND_TOKEN })

  useEffect(() => {
    const retrieveToken = async () => {
      try {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
          const messaging = getMessaging(firebaseApp)

          // Retrieve the notification permission status
          const permission = await Notification.requestPermission()
          setNotificationPermissionStatus(permission)

          // Check if permission is granted before retrieving the token
          if (permission === 'granted') {
            const currentToken = await getToken(messaging, {
              vapidKey: 'BDLdcsOxl7c3LD-CF6c_G7n9fQ7jp5znVhmgUMivD-C_KKziSt2cUpH5rgGbxqnqQYj03wnSI4kiS6DlXaKIzbA'
            })

            if (currentToken) {
              setToken(currentToken)
              mutate({
                firebase_token: currentToken
              })
            } else {
            }
          }
        }
      } catch (error) {}
    }

    retrieveToken()
  }, [mutate])

  return { fcmToken: token, notificationPermissionStatus }
}

export default useFcmToken
