import { useTranslation } from 'react-i18next'
import GetContainer from '../../../components/get-container'
import { IStatistics } from '../../../types/types'
import { useState } from 'react'
import moment from 'moment'

export const TeacherStatistics = () => {
  const { t, i18n } = useTranslation()
  const [date, setDate] = useState<any>(new Date())
  return (
    <div className='payment-info'>
      <div className='payment-info__head'>
        <div className='payment-info__wrap'>
          <div className='payment-info__title'>{t('Summary')}</div>
        </div>

        <div className='payment-info__wrap'>
          <div className='classes-coming__arrows'>
            <div className='classes-coming__prev'>
              <div
                className='arrow-left'
                onClick={() => setDate(new Date(moment(date).clone().subtract(1, 'month').format('YYYY-MM')))}
              >
                <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M14 16L10 12L14 8'
                    stroke='white'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              <span>{t(moment(date).clone().subtract(1, 'month').format('MMM'))}</span>
            </div>
            <div className='classes-coming__date'>{moment(date).format('MMMM YYYY')}</div>
            <div
              className='classes-coming__next'
              onClick={() => setDate(new Date(moment(date).clone().add(1, 'month').format('YYYY-MM')))}
            >
              <span>{t(moment(date).clone().add(1, 'month').format('MMM'))}</span>
              <div className='arrow-right'>
                <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M10 16L14 12L10 8'
                    stroke='white'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='history-table'>
        <div className='history-table__head'>
          <div>{t('Subject')}</div>
          <div>{t('Completed')}</div>
          <div>{t('Cancel')}</div>
          <div>{t('Missed')}</div>
          <div>{t('Reserved')}</div>
        </div>
        <GetContainer url='/teacher/statistics' params={{ date: moment(date).format('YYYY-MM') }}>
          {({ data }: { data: { data: IStatistics } }) => (
            <>
              {data?.data?.statistics?.map(item => (
                <div className='history-table__row'>
                  <div className='history-table__item'>{item?.subject_name?.[i18n.language]}</div>
                  <div className='history-table__item'>
                    {item.completed.hours}
                    {t('h')}({item?.completed?.count}
                    {t('class')})
                  </div>
                  <div className='history-table__item'>
                    {item.cancel.hours}
                    {t('h')}({item?.cancel?.count}
                    {t('class')})
                  </div>
                  <div className='history-table__item'>
                    {item.missed.hours}
                    {t('h')}({item?.missed?.count}
                    {t('class')})
                  </div>
                  <div className='history-table__item'>
                    {item.reserved?.hours}
                    {t('h')}({item?.reserved?.count}
                    {t('class')})
                  </div>
                </div>
              ))}
              <p>
                {t('All slots count')}: {data?.data?.general_statistics}
              </p>
            </>
          )}
        </GetContainer>
      </div>
    </div>
  )
}
