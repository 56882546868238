import { useState } from 'react'
import GetContainer from '../../../components/get-container'
import { ITariff } from '../../../types/types'
import TariffCard from './components/TariffCard'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

export const Payment = () => {
  const [value, setValue] = useState(0)
  return (
    <div>
      <GetContainer url='student/tariffs'>
        {({ data }) => (
          <ul className='rates-list'>
            {data?.data?.map((tariff: ITariff) => (
              <TariffCard tariff={tariff} key={tariff.id} />
            ))}
          </ul>
        )}
      </GetContainer>
      <div className='calc-card'>
        <div className='calc-content'>
          <div className='calc__title'>Classes per week</div>
          <div className='calc__text'>
            Our free Starter Site plan is great for trying Webflow. Purchase a paid Site plan to publish, host, and
            unlock additional features.
          </div>
        </div>
        <div className='calc-wrap'>
          <div className='calc-input'>
            <div className='calc-input__coin'>Coin</div>
            <div className='calc-input__input'>
              <Slider marks={{}} min={0} max={9} value={value} onChange={e => setValue(Number(e))} />
            </div>
            <div className='calc-input__values'>
              <span className={`${value === 0 ? 'active' : ''}`}>100</span>
              <span className={`${value === 1 ? 'active' : ''}`}>200</span>
              <span className={`${value === 2 ? 'active' : ''}`}>300</span>
              <span className={`${value === 3 ? 'active' : ''}`}>400</span>
              <span className={`${value === 4 ? 'active' : ''}`}>500</span>
              <span className={`${value === 5 ? 'active' : ''}`}>600</span>
              <span className={`${value === 6 ? 'active' : ''}`}>700</span>
              <span className={`${value === 7 ? 'active' : ''}`}>800</span>
              <span className={`${value === 8 ? 'active' : ''}`}>900</span>
              <span className={`${value === 9 ? 'active' : ''}`}>1000</span>
            </div>
          </div>
          <div className='calc-info'>
            <div className='calc-info__price'>
              <div>
                <span>1</span> mln
              </div>
              <div>
                <span>100</span> ming
              </div>
              <div className='som'>so’m</div>
            </div>
            <a href='#' className='btn btn-lightgreen'>
              Sotib olish
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
