import React from 'react'
import GetContainer from '../../../../../components/get-container'
import { ISlot } from '../../../../../types/types'
import { useTranslation } from 'react-i18next'

interface IProps {
  id: string
}

const LessonInfo = (props: IProps) => {
  const { i18n, t } = useTranslation()
  return (
    <GetContainer url={'teacher/slots/' + props.id}>
      {({ data }: { data: { data: ISlot } }) => (
        <div className='moreLessonItem'>
          <div className='moreLessonItem-title'>
            <div className='lessonName'>{data?.data?.date}</div>
            <div className='lessonTime'>{data?.data?.time}</div>
          </div>
          <div className='content show'>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Lesson')}: {data?.data?.schedule?.lesson?.name?.[i18n.language]}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Subject')}: {data?.data?.schedule?.group?.subject?.name?.[i18n.language]}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Lesson type')}: {data?.data?.schedule?.group?.type}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Students count')}: {data?.data?.schedule?.group?.count}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Status')}: {t(data?.data?.schedule?.main_status)}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Sub status')}: {t(data?.data?.schedule?.sub_status)}
              </div>
            </div>
            {!!data?.data?.schedule?.zoom_link && (
              <div className='contentLesson'>
                <div className='contentLesson-title'>
                  <a href={data?.data?.schedule?.zoom_link}>{t('Zoom link')}</a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </GetContainer>
  )
}

export default LessonInfo
