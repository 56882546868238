import { useParams } from 'react-router-dom'
import CourseSingleBanner from './components/CourseSingleBanner'
import { useTranslation } from 'react-i18next'
import GetContainer from '../../../components/get-container'
import CroupCard from './components/GroupCard'
import { IGroupCourse } from '../../../types/types'
import WithHTML from '../../../components/with-html'

export const GroupCoursesSingle = () => {
  const { slug } = useParams()
  const { t, i18n } = useTranslation()
  return (
    <GetContainer url={'student/course_parts/' + slug}>
      {({ data }) => (
        <div className='courses-group-single'>
          <CourseSingleBanner item={data?.data} />
          <div className='courses-group-single__content'>
            <div className='courses-group-single__title'>{t('Description')}</div>
            <div className='courses-group-single__text'>
              <WithHTML html={data?.data?.course?.description?.[i18n.language]} />
            </div>
          </div>
          <div className='courses-group-single__wrap'>
            {data?.data?.groups?.map((group: IGroupCourse) => (
              <CroupCard item={group} key={group?.id} />
            ))}
          </div>
        </div>
      )}
    </GetContainer>
  )
}
