import React from 'react'
import { useTranslation } from 'react-i18next'
import GetContainer from '../../../../components/get-container'
import { ISlot } from '../../../../types/types'
import moment from 'moment'

function CompleteClasses() {
  const { t, i18n } = useTranslation()
  return (
    <div className='classes-main'>
      <div className='classes__title'>{t('Incomplete class')}</div>
      <div className='classes-main__incomplete'>
        <div className='history-table'>
          <div className='history-table__head'>
            <div>{t('Subject')}</div>
            <div>{t('Date time')}</div>
            <div>{t('Lesson')}</div>
            <div>{t('Programm')}</div>
            <div>{t('Students')}</div>
            <div></div>
          </div>
          <GetContainer url='teacher/slots' params={{ status: 'lesson_is_underway' }}>
            {({ data }) =>
              data?.data?.map((item: ISlot) => (
                <div className='history-table__row' key={item.id}>
                  <div className='history-table__class'>{item?.schedule?.group?.subject?.name?.[i18n.language]}</div>
                  <div className='history-table__date'>{`${item?.schedule?.group?.start_date}--${item?.schedule?.group?.end_date} ${item?.schedule?.group?.time}`}</div>
                  <div className='history-table__lesson'>{item?.schedule?.group?.subject?.name?.[i18n.language]}</div>
                  <div className='history-table__programm'>{item?.schedule?.lesson?.name?.[i18n.language]}</div>
                  <div className='history-table__student'>
                    {item?.schedule?.group?.students
                      ?.map(student => `${student?.firstname} ${student?.lastname}`)
                      ?.join(', ')}
                  </div>
                  <a href={'/teacher/classes/' + item.schedule_id + '?type=complete'} className='history-table__btns'>
                    <button className='btn'>{t('Lesson plan')}</button>
                  </a>{' '}
                  {!!item?.schedule?.zoom_link && (
                    <a
                      href={item?.schedule?.zoom_link}
                      target={'_blank'}
                      rel='noreferrer'
                      className='history-table__btns'
                    >
                      <button className='btn'>{t('Enter zoom')}</button>
                    </a>
                  )}
                </div>
              ))
            }
          </GetContainer>
        </div>
      </div>
      <div className='classes-main__complete'>
        <div className='classes__title'>{t('Complete class')}</div>
        <div className='history-table'>
          <GetContainer url='teacher/slots' params={{ status: 'the_lesson_is_over' }}>
            {({ data }) =>
              data?.data?.map((item: ISlot) => (
                <div className='history-table__row' key={item.id + item.date}>
                  <div className='history-table__date'>
                    {moment(item?.date).format('MMMM Do, YYYY')} {item?.time}
                  </div>
                  <div className='history-table__programm'>
                    {item?.schedule?.group?.subject?.name?.[i18n?.language]}
                  </div>
                  <div className='history-table__lesson'>{item?.schedule?.group?.subject?.title?.[i18n?.language]}</div>
                  <div className='history-table__student'>{item?.schedule?.lesson?.name?.[i18n?.language]}</div>
                  <div className='history-table__btns'>
                    <a href={`/teacher/classes/${item.schedule_id}`} className='btn'>
                      {t('Lesson plan')}
                    </a>
                    <button className='btn btn-white'>{t('Completed')}</button>
                  </div>
                </div>
              ))
            }
          </GetContainer>
        </div>
      </div>
    </div>
  )
}

export default CompleteClasses
