import React from 'react'
import './SuccessModal.scss'
import Modal from 'react-modal'

const customStyles = {
  overlay: { zIndex: 1000, border: 'none' },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    backgroundColor: 'transparent'
  }
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

interface SuccessModalProps {
  open: boolean
  message: string
  onClose: () => void
}

const SuccessModal: React.FC<SuccessModalProps> = ({ message, onClose, open }) => {
  return (
    // @ts-ignore
    <Modal
      isOpen={open}
      //   onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      {/* <div className='modal-overlay' style={{ display: open ? 'block' : 'none' }}> */}
      <div className='success-modal'>
        <div className='modal-content'>
          <div className='icon-wrapper'>
            {/* You can add a success icon here, for now a simple emoji */}
            <span className='success-icon'>✅</span>
          </div>
          <p className='message'>{message}</p>
          <button className='close-btn' onClick={onClose}>
            Yopish
          </button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  )
}

export default SuccessModal
