import { Fragment, ReactNode, useEffect, useState } from 'react'
import React from 'react'
import ReactPaginate from 'react-paginate'
import GetContainer from '../get-container'
interface IProps {
  url: string
  params?: object
  children: (props: any) => ReactNode
  onSuccess?: (data: any) => void
  onError?: (data: any) => void
  hideLoading?: boolean
}

function GetContainerPagination(props: IProps) {
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const handlePageClick = (event: any) => {
    setPage(event.selected)
  }

  return (
    <Fragment>
      <GetContainer
        {...props}
        params={{ page: page + 1, perPage: 10, ...(props.params && props.params) }}
        onSuccess={data => setTotal(data?.meta?.total)}
      >
        {data => props.children(data)}
      </GetContainer>
      {!!total && (
        // @ts-expect-error Server Component
        <ReactPaginate
          nextLabel='>'
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={Math.ceil(total / 10)}
          previousLabel='<'
          pageClassName='pagination'
          pageLinkClassName='page-link'
          previousClassName='arrow arrow-left'
          previousLinkClassName='page-link'
          nextClassName='arrow arrow-right'
          nextLinkClassName='page-link'
          breakLabel='...'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          containerClassName='pagination'
          activeClassName='current'
          initialPage={0}
          renderOnZeroPageCount={null}
        />
      )}
    </Fragment>
  )
}

export default GetContainerPagination
