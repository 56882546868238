import React, { ChangeEvent, useContext, useMemo, useRef } from 'react'
import GetContainer from '../../../../../components/get-container'
import './styles.scss'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ISlot } from '../../../../../types/types'
import CalendarFill from '../../../../../assets/img/icons/CalendarFill'
import Form from '../../../../../components/form'
import * as Yup from 'yup'
import { Controller } from 'react-hook-form'
import { RandomContext } from '../../../../../providers/RandomProvider'

const validationSchema = Yup.object().shape({
  start_date: Yup.string().required('This field is required!'),
  end_date: Yup.string().required('This field is required!'),
  times: Yup.array(Yup.string().required('This field is required!'))
})

interface IProps {
  date: string
  onSuccess: () => void
}

function DailySlots(props: IProps) {
  const { t } = useTranslation()
  const fromRef = useRef<HTMLInputElement>(null)
  const toRef = useRef<HTMLInputElement>(null)
  const { setRandom }: any = useContext(RandomContext)

  const isEditable = useMemo(() => {
    const minDate = moment().add(1, 'days')

    // Bugungi sana + 2 oy
    const maxDate = moment().add(2, 'months')

    if (moment(props.date).isBetween(minDate, maxDate, 'day', '[]')) {
      return true
    } else {
      return false
    }
  }, [props.date])

  const getDates = (times: string[], slots: ISlot[]) => {
    return times?.map(time => {
      const split = time?.split(':')
      return {
        time: time,
        label: split[1] === '30' ? `${time}~${Number(split[0]) + 1}:00` : `${time}~${split[0]}:30`,
        status: slots?.some(slot => `${slot.time?.split(':')[0]}:${slot.time?.split(':')[1]}` === time)
          ? slots?.find(slot => `${slot.time?.split(':')[0]}:${slot.time?.split(':')[1]}` === time)?.schedule
            ? 'scheduled'
            : 'not_scheduled'
          : 'default'
      }
    })
  }
  return (
    <Form
      url='teacher/slots/daily'
      validationSchema={validationSchema}
      onSuccess={() => {
        setRandom(Math.random())
        props.onSuccess()
      }}
      method='POST'
      initialValues={{
        start_date: props.date, //required
        end_date: props.date, //required
        times: [] //required
      }}
    >
      {({ form, createInfo, handleFinish }) => {
        const {
          control,
          formState: { errors },
          handleSubmit,
          watch,
          setValue
        } = form
        return (
          <GetContainer
            url={'teacher/slots/daily?date=' + props.date}
            onSuccess={data =>
              setValue(
                'times',
                data?.data?.map((e: ISlot) => e.time)
              )
            }
          >
            {({ data }) => (
              <GetContainer url='teacher/slots/time'>
                {({ data: times }) => (
                  <div className='schedule-container'>
                    {/* Sana va vaqt jadvali */}
                    <div className='schedule-header'>{`${moment(props.date).format('dddd')}, ${moment(
                      props.date
                    ).format('MMMM')}. ${moment(props.date).format('DD, YYYY')}`}</div>
                    <div className='schedule-subtitle'>{t('Create a lesson at your convenience')}</div>

                    {/* Vaqt slotlari */}
                    <Controller
                      control={control}
                      name='times'
                      render={({ field: { onChange, value, ref } }) => (
                        <div className='time-slots'>
                          {getDates(times, data?.data)?.map((slot, index) => (
                            <label key={index} className={`time-slot ${slot.status}`}>
                              <input
                                type='checkbox'
                                checked={value?.some((time: string) => time === slot.time)}
                                value={slot.time}
                                disabled={slot.status === 'scheduled' || !isEditable}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  const { value: tVale, checked } = event.target
                                  if (checked) {
                                    onChange([...value, tVale])
                                  } else {
                                    onChange(value.filter((time: string) => time !== tVale))
                                  }
                                }}
                              />
                              <span>{slot.label}</span>
                            </label>
                          ))}
                        </div>
                      )}
                    />

                    {/* Sana tanlash uchun inputlar */}
                    {watch('repeat') && (
                      <div className='date-picker'>
                        <Controller
                          control={control}
                          name='start_date'
                          render={({ field: { onChange, value } }) => (
                            <div className='date-picker-input'>
                              <input
                                type='date'
                                value={value}
                                onChange={e => onChange(moment(e.target.value).format('YYYY-MM-DD'))}
                                placeholder='...dan'
                                ref={fromRef}
                              />
                              {/* @ts-ignore */}
                              <div onClick={() => fromRef?.current?.showPicker()}>
                                <CalendarFill />
                              </div>
                            </div>
                          )}
                        />

                        <Controller
                          control={control}
                          name='end_date'
                          render={({ field: { onChange, value } }) => (
                            <div className='date-picker-input'>
                              <input
                                type='date'
                                value={value}
                                onChange={e => onChange(moment(e.target.value).format('YYYY-MM-DD'))}
                                placeholder='...ga'
                                ref={toRef}
                              />
                              {/* @ts-ignore */}
                              <div onClick={() => toRef?.current?.showPicker()}>
                                <CalendarFill />
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    )}

                    <div className='schedule-row'>
                      {/* Jadvalni takrorlash uchun checkbox */}
                      <Controller
                        control={control}
                        name='repeat'
                        render={({ field: { onChange, value, ref } }) => (
                          <div className='repeat-schedule'>
                            <input
                              type='checkbox'
                              id='repeat'
                              disabled={!isEditable}
                              value={value}
                              onChange={e => onChange(e.target.checked)}
                            />
                            <label htmlFor='repeat'>{t('Repeat this table')}</label>
                          </div>
                        )}
                      />

                      {/* Sanani saqlash tugmasi */}
                      {isEditable && (
                        <div className='save-button'>
                          <button type='button' onClick={handleSubmit(data => handleFinish(data))}>
                            {t('Save time')}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </GetContainer>
            )}
          </GetContainer>
        )
      }}
    </Form>
  )
}

export default DailySlots
