import React from 'react'
import { IReview } from '../../../../types/types'
import { useTranslation } from 'react-i18next'

interface IProps {
  data: IReview
}

function ReviewInfo(props: IProps) {
  const { t, i18n } = useTranslation()
  return (
    <ul className='reviews-add__info'>
      <li>
        <span>{t('Lesson')}:</span>
        <span>{props.data?.schedule?.lesson?.name?.[i18n.language]}</span>
      </li>
      <li>
        <span>{t('Date and Time:')}</span>
        <span>{props.data?.created_at}</span>
      </li>
      <li>
        <span>{t('Teacher')}:</span>
        <span>{`${props?.data?.teacher?.firstname} ${props?.data?.teacher?.lastname}`}</span>
      </li>
    </ul>
  )
}

export default ReviewInfo
