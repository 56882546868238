import { useTranslation } from 'react-i18next'
import GetContainerPagination from '../../../components/get-container-pagination'

export const Coin = () => {
  const { i18n, t } = useTranslation()
  return (
    <div className='coin-info'>
      <GetContainerPagination url='/student/coin_transactions'>
        {({ data }) => (
          <>
            <div className='coin__title'>
              {t('Next charge date')}: <span>{data?.data?.[data?.data?.length - 1]?.created_at}</span>
            </div>
            <div className='coin-table table'>
              <table>
                <tbody>
                  <tr className='head'>
                    <td>{t('Date')}</td>
                    <td>{t('Amount')}</td>
                    <td>{t('Description')}</td>
                  </tr>
                  {data?.data?.map((item: any) => (
                    <tr key={item?.id}>
                      <td>{item?.created_at}</td>
                      <td className={`bold ${item?.amount > 0 ? 'green' : 'red'}`}>{item?.amount}</td>
                      <td>{item?.comment?.[i18n.language]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </GetContainerPagination>
    </div>
  )
}
