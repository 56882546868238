import { useParams } from 'react-router-dom'
import GetContainer from '../../../components/get-container'
import TeacherSubject from './components/TeacherSubject'
import TeacherInfo from './components/TeacherInfo'
import { ISubject } from '../../../types/types'
import { useTranslation } from 'react-i18next'

export const TeachersSingle = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  return (
    <GetContainer url={'/student/teachers/' + id}>
      {({ data }) => (
        <div className='teachers-single__wrap'>
          <div className='teachers-courses'>
            <div className='teachers-courses__title'>{t('Courses')}</div>
            <ul className='teachers-courses__list'>
              {data?.data?.subjects?.map((item: ISubject) => (
                <TeacherSubject key={item?.slug} item={item} teacherId={id || ''} />
              ))}
            </ul>
          </div>

          <TeacherInfo item={data?.data} />
        </div>
      )}
    </GetContainer>
  )
}
