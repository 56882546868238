import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Head from './components/Head'
import ReviewInfo from './components/ReviewInfo'
import Choose from './components/Choose'
import Form from '../../../components/form'
import * as Yup from 'yup'
import GetContainer from '../../../components/get-container'
import { IReview } from '../../../types/types'
import { Controller } from 'react-hook-form'
import { Fragment, useState } from 'react'
import RequestModal from '../../../components/request-modal'

const initialValues = {
  schedule_id: 0,
  message: '',
  stars: '3'
}
const validationSchema = Yup.object().shape({
  schedule_id: Yup.number().required('Phone is required'),
  message: Yup.string().required('Phone is required'),
  stars: Yup.number().required('Phone is required')
})

export const TeacherReviewsAdd = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { schedule_id, id } = useParams()
  const navigate = useNavigate()
  return (
    <Form
      url='student/reviews'
      initialValues={{ ...initialValues, schedule_id: schedule_id }}
      validationSchema={validationSchema}
      method='post'
      onSuccess={data => {
        setOpen(true)
      }}
      onError={data => {
        setOpen(true)
      }}
    >
      {({ form, handleFinish, createInfo }) => {
        const {
          control,
          formState: { errors },
          handleSubmit
        } = form
        return (
          <div className='reviews-single reviews-add'>
            <GetContainer url='/student/reviews' params={{ subject_id: id }}>
              {({ data }: { data: { data: IReview[] } }) => (
                <Fragment>
                  <Head teacher={data?.data[data?.data?.length - 1].teacher} />
                  <div className='reviews-add__title'>{t('How was your lesson with your teacger today?')}</div>
                  <ReviewInfo data={data?.data[data?.data?.length - 1]} />
                </Fragment>
              )}
            </GetContainer>
            <div className='reviews-add__title'>{t('How satisfied were you with your teacher?')}</div>
            <Controller
              name={'stars'}
              control={control}
              render={({ field }) => <Choose value={field.value} onChange={value => field.onChange(value)} />}
            />
            <div className='reviews-add__title'>
              {t('Let us know below if you hev any feedback or comment about the teacher')}
            </div>
            <div className='reviews-add__textarea'>
              <Controller
                name={'message'}
                control={control}
                render={({ field }) => (
                  <textarea
                    placeholder={'Comment: '}
                    value={field.value}
                    onChange={e => field.onChange(e.target.value)}
                  />
                )}
              />
            </div>
            <div className='reviews-add__btn'>
              <button className='btn btn-orange' onClick={handleSubmit(data => handleFinish(data))}>
                {t('Submit')}
              </button>
            </div>
            <RequestModal
              isOpen={open}
              close={() => {
                navigate(-1)
                setOpen(false)
              }}
              title={`Add review title`}
              error={createInfo?.error}
              isSuccess={createInfo?.isSuccess}
              description={<div />}
              send={() => {}}
              acceptBtnText='Yes, I will'
              cancelBtnText='No'
              successTitle={`Add review success title`}
              successDesc={`Add review success desc`}
              errorTitle={`Add review error title`}
            />
          </div>
        )
      }}
    </Form>
  )
}
