import * as React from "react"
import { SVGProps } from "react"
const Timer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="#83878F"
      d="M8.999 3.486a6.51 6.51 0 0 0-6.503 6.502c0 3.585 2.918 6.51 6.503 6.51A6.51 6.51 0 0 0 15.5 9.997c0-3.585-2.917-6.51-6.502-6.51Zm.562 6.262a.567.567 0 0 1-.562.563.567.567 0 0 1-.563-.563v-3.75c0-.307.255-.562.563-.562.307 0 .562.255.562.563v3.75Zm1.605-7.16H6.831a.544.544 0 0 1 0-1.088h4.335a.544.544 0 0 1 0 1.087Z"
    />
  </svg>
)
export default Timer
