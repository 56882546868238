import { useTranslation } from 'react-i18next'
import MyCalendar from './component/bigCalendar'
import GetContainer from '../../../components/get-container'
import moment from 'moment'
import { ISchedule, ISlot } from '../../../types/types'
import { MEDIA_URL } from '../../../configs/request'
import { useContext } from 'react'
import { RandomContext } from '../../../providers/RandomProvider'
import LessonCard from './component/lesson-card'

export const Calendar = () => {
  const { t, i18n } = useTranslation()
  const { random } = useContext(RandomContext)

  return (
    <>
      <div className='courses-waiting'>
        <div className='calendar-title'>{t('Pending courses')}</div>
        <div className='history-table'>
          <div className='history-table__head'>
            <div>{t('Subject')}</div>
            <div>{t('Teacher')}</div>
            <div>{t('Start date')}</div>
            <div>{t('End date')}</div>
            <div></div>
          </div>
          <GetContainer url='/student/groups/request'>
            {({ data }) =>
              data?.data?.map((item: ISchedule) => (
                <div className='history-table__row'>
                  <div className='course-type'>
                    <span className='course-type__ico'>
                      <img src={MEDIA_URL + item?.group?.subject?.icon} alt='icon' />
                    </span>
                    <span>
                      {item?.group?.subject?.name?.[i18n.language]}
                      <p>{item?.group?.subject?.title?.[i18n.language]}</p>
                    </span>
                  </div>
                  <div className='history-table__teacher'>
                    <img
                      src={
                        item?.group?.teacher?.photo
                          ? MEDIA_URL + item?.group?.teacher?.photo
                          : require('../../../assets/img/mock/student.jpg')
                      }
                      alt='name'
                    />
                    <span>{`${item?.group?.teacher?.firstname} ${item?.group?.teacher?.lastname}`}</span>
                  </div>
                  <div className='history-table__date'>{item.group?.start_date}</div>
                  <div className='history-table__work'>{item?.group?.end_date}</div>
                  <div className='history-table__waiting'>{t('Waiting')}</div>
                  {/* <a href='#' className='history-table__link' /> */}
                </div>
              ))
            }
          </GetContainer>
        </div>
      </div>
      <div className='courses-today'>
        <div className='calendar-title'>{t(`Today's courses`)}</div>
        <div className='history-table'>
          <div className='history-table__head'>
            <div></div>
            <div>{t('Date/ Time')}</div>
            <div>{t('Lesson')}</div>
            <div>{t('Type')}</div>
            <div>{t('Teacher')}</div>
            <div></div>
          </div>
          <GetContainer
            url={'/student/slots'}
            params={{ status: 'ready_for_lesson,lesson_is_underway', date: moment().format('YYYY-MM-DD'), random }}
          >
            {({ data }) => data?.data?.map((item: ISlot) => <LessonCard data={item} key={item.id} />)}
          </GetContainer>
        </div>
      </div>

      <MyCalendar />
    </>
  )
}
