import * as React from "react"
import { SVGProps } from "react"
const Menu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    fill="none"
    viewBox="0 0 60 60"
    {...props}
  >
    <rect width={60} height={60} fill="#fff" rx={30} />
    <path
      stroke="#1F4F51"
      strokeLinecap="round"
      strokeWidth={2}
      d="M18.648 21.895h23.514m-23.514 8.108h23.514M18.648 38.11h23.514"
    />
  </svg>
)
export default Menu
