import { useParams, useSearchParams } from 'react-router-dom'
import GetContainer from '../../../components/get-container'
import { IReport, ISchedule, IStudentHomework } from '../../../types/types'
import { useTranslation } from 'react-i18next'
import IndividualCompleted from './components/IndividualCompleted'
import GroupCompleted from './components/GroupCompleted'
import { MEDIA_URL } from '../../../configs/request'
import moment from 'moment'
import WithHTML from '../../../components/with-html'

export const ClassesSingle = () => {
  const { id } = useParams()
  const { t, i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  return (
    <GetContainer url={'/teacher/group_schedules/' + id}>
      {({ data }: { data: { data: ISchedule } }) => (
        <div className='classes-single'>
          <div className='classes-single__title'>{data?.data?.group?.subject?.name?.[i18n.language]}</div>
          <span>
            {t('Date')}: {data?.data?.date}
          </span>
          <div className='homework-wrap'>
            <div className='homework-main'>
              <div className='homework__title'>{data?.data?.group?.subject?.title?.[i18n.language]}</div>
              <div className='classes-single__title'>{data?.data?.lesson?.name?.[i18n.language]}</div>
              <ul className='homework-info'>
                <li>
                  {t('GRADE')} <span>{data?.data?.lesson?.unit?.grade?.name?.[i18n?.language]}</span>
                </li>
                <li>
                  {t('UNIT')} <span>{data?.data?.lesson?.unit?.name?.[i18n?.language]}</span>
                </li>
                <li>
                  {t('LESSON')} <span>{data?.data?.lesson?.name?.[i18n.language]}</span>
                </li>
              </ul>
              <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <WithHTML html={data?.data?.lesson?.lesson_plan?.[i18n.language]} />
              </div>
              {searchParams.get('type') === 'complete' ? (
                !!data?.data ? (
                  data?.data?.group?.type === 'individual' ? (
                    <IndividualCompleted data={data?.data} />
                  ) : (
                    <GroupCompleted data={data?.data} />
                  )
                ) : null
              ) : null}
            </div>
            <GetContainer url={data?.data?.group_id ? `/teacher/groups/${data?.data?.group_id}/reports` : ''}>
              {({ data: reports }) => (
                <div className='homework-side'>
                  {/* <div className='homework-side__head'>
                    <div className='homework-side__name'>Hana Sato (8 years old, female)</div>
                    <span className='homework-side__add'>
                      <svg width={12} height={12} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M6 1V11' stroke='#F5F6FA' strokeWidth={2} strokeLinecap='round' />
                        <path d='M11 6L1 6' stroke='#F5F6FA' strokeWidth={2} strokeLinecap='round' />
                      </svg>
                    </span>
                  </div> */}
                  {reports?.data?.map((report: IReport) => (
                    <div className='homework-card' key={report?.id} style={{ marginBottom: '1rem' }}>
                      <div className='homework-card__head'>
                        <img
                          src={
                            report?.teacher?.photo
                              ? MEDIA_URL + report?.teacher?.photo
                              : require('../../../assets/img/mock/student.jpg')
                          }
                          alt='student'
                        />
                        <span>{`${report?.teacher?.firstname} ${report?.teacher?.lastname} ${t('at')} ${moment(
                          report?.created_at
                        ).format('D MMM HH:mm')}`}</span>
                      </div>
                      <div className='homework-card__text'>{report?.report}</div>
                    </div>
                  ))}
                  <GetContainer url={`teacher/group_schedules/${id}/homework`}>
                    {({ data }: { data: { data: IStudentHomework[] } }) => (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <h3>{t('Homeworks')}</h3>
                        {data?.data
                          ?.filter(homework => !!homework.file)
                          ?.map(homework => (
                            <a
                              href={MEDIA_URL + homework?.file}
                              target='_blank'
                              rel='noreferrer'
                            >{`${homework?.student?.firstname} ${homework?.student?.lastname}`}</a>
                          ))}
                      </div>
                    )}
                  </GetContainer>
                </div>
              )}
            </GetContainer>
          </div>
        </div>
      )}
    </GetContainer>
  )
}
