import GetContainer from '../../../components/get-container'
import { ICount } from '../../../types/types'
import ReviewCard from './components/ReviewCard'

export const TeacherReviews = () => {
  return (
    <div className='reviews-list'>
      <GetContainer url='/student/reviews/count'>
        {({ data }) => data?.map((item: ICount, index: number) => <ReviewCard item={item} key={index} />)}
      </GetContainer>
    </div>
  )
}
