import * as React from "react"
import { SVGProps } from "react"
const Play = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <circle cx={18} cy={18} r={18} fill="#fff" />
    <path
      fill="#FB9851"
      d="M24.5 17.134a1 1 0 0 1 0 1.732l-9 5.196a1 1 0 0 1-1.5-.866V12.804a1 1 0 0 1 1.5-.866l9 5.196Z"
    />
  </svg>
)
export default Play
