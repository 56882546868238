import React, { Fragment, ReactElement, useContext, useMemo } from 'react'
import ProfileInfo from '../first-layout/components/profile-info'
import ProfileInfoCard from '../first-layout/components/profile-menu-card'
import ProfileMenuList from '../first-layout/components/profile-menu-list'
import { StoryTime } from '../first-layout/components/story-time'
import Lang from '../../assets/img/icons/Lang'
import ChevronDownBlack from '../../assets/img/icons/ChevronDownBlack'
import CalendarNav from '../../assets/img/icons/CalendarNav'
import { profileMenuData, sideMenuData, teachersMenuData } from '../../data/menuData'
import { IProfileMenuItem } from '../first-layout/components/profile-menu-item'
import { Outlet, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const LANGUAGES = [
  {
    name: 'O’zbek',
    value: 'uz'
  },
  {
    name: 'English',
    value: 'en'
  },
  {
    name: 'Русский',
    value: 'ru'
  }
]

interface ISecondLayout {
  menuData: IProfileMenuItem[]
  initial: string
  parent: string
  routerData: {
    path: string
    element: ReactElement
  }[]
}

function SecondLayout(props: ISecondLayout) {
  const location = useLocation()
  const { user } = useContext(AuthContext)
  const { i18n, t } = useTranslation()

  const page = useMemo(() => {
    const path = location?.pathname?.split('/')?.[2]
    if (user?.role === 'student') {
      return sideMenuData?.find(el => el.path?.split('/')?.[1] === path)
    } else {
      return teachersMenuData?.find(el => el.path?.split('/')?.[1] === path)
    }
  }, [location?.pathname, user?.role])
  return (
    <Fragment>
      {/* SIDE-INFO */}
      <div className='sideinfo'>
        <ProfileInfo />
        <div className='profile-menu'>
          {user?.role === 'student' && (
            <ProfileInfoCard>
              <ProfileMenuList data={profileMenuData} parent={props.parent} />
            </ProfileInfoCard>
          )}

          <ProfileInfoCard>
            <ProfileMenuList data={props.menuData} parent={props.parent} />
          </ProfileInfoCard>
          {user?.role === 'student' && <StoryTime />}
        </div>
      </div>

      {/* SIDEBAR MOBILE PHOTO*/}
      {/*<div className='sidebar-photo'>*/}
      {/*  <img src={require('../../assets/img/mock/student.jpg')} alt='student' />*/}
      {/*</div>*/}

      <div className='support'>
        {/* HEADER */}
        <header className='header'>
          <div className='header__name'>{t(page?.title || '')}</div>

          <nav className='header-nav'>
            {/* Lang */}
            <div className='header-lang'>
              <div className='header-lang__btn'>
                <div className='header-lang__btn-wrap'>
                  <Lang />
                  <div>{LANGUAGES?.find(lang => lang.value === i18n.language)?.name}</div>
                  <ChevronDownBlack />
                </div>
              </div>
              <div className='header-lang__list'>
                {LANGUAGES?.map(lang => (
                  <a
                    key={lang?.value}
                    onClick={() => {
                      i18n.changeLanguage(lang.value)
                      localStorage.setItem('lang', lang.value)
                    }}
                    className={i18n.language === lang.value ? 'current' : ''}
                  >
                    {lang.name}
                  </a>
                ))}
              </div>
            </div>
            {/* Lang end */}

            {/* date,notification,message */}
            <div className='header-nav__menu'>
              <a href={`/${user?.role}/${props.parent}/calendar`} className='header-nav__date'>
                <CalendarNav />
                <span>{moment().format('DD.MM.YYYY')}</span>
              </a>
              {user?.role === 'student' && (
                <Fragment>
                  <a href={`/${user?.role}/${props.parent}/messages`}>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M4.0404 7.13287C4.77451 7 5.74936 7 7.22222 7H8.77778C10.9558 7 12.0448 7 12.8766 7.42964C13.6084 7.80757 14.2033 8.4106 14.5761 9.15232C15 9.99554 15 11.0994 15 13.3071V15.4348C15 15.6552 15 15.7655 14.9956 15.8586C14.9253 17.3646 14.0255 18.6473 12.7481 19.2486M4.0404 7.13287C3.68896 7.19648 3.39269 7.29055 3.12337 7.42964C2.39163 7.80757 1.7967 8.4106 1.42386 9.15232C1 9.99554 1 11.0994 1 13.3071V16.6406C1 18.2828 2.31338 19.6141 3.93351 19.6141H4.40154C5.00751 19.6141 5.42186 20.2345 5.19681 20.8048C4.87911 21.6098 5.79383 22.3377 6.48993 21.8337L8.5205 20.3635C8.54121 20.3485 8.55157 20.341 8.56175 20.3337C9.21041 19.8704 9.98339 19.6193 10.7769 19.6142C10.7894 19.6141 10.806 19.6141 10.8392 19.6141C11.0818 19.6141 11.2031 19.6141 11.295 19.6097C11.8125 19.5849 12.3032 19.4581 12.7481 19.2486M4.0404 7.13287C4.09617 6.08171 4.22894 5.35029 4.54497 4.73005C5.02433 3.78924 5.78924 3.02433 6.73005 2.54497C7.79961 2 9.19974 2 12 2H14C16.8003 2 18.2004 2 19.27 2.54497C20.2108 3.02433 20.9757 3.78924 21.455 4.73005C22 5.79961 22 7.19974 22 10V14.2283C22 16.3114 20.3114 18 18.2283 18H17.6266C16.8475 18 16.3147 18.7869 16.6041 19.5103C17.0126 20.5314 15.8365 21.4546 14.9415 20.8154L12.7481 19.2486'
                        stroke='currentColor'
                        stroke-width='1.5'
                      />
                      <path
                        d='M6 14C6 14.5523 5.55228 15 5 15C4.44772 15 4 14.5523 4 14C4 13.4477 4.44772 13 5 13C5.55228 13 6 13.4477 6 14Z'
                        fill='currentColor'
                      />
                      <path
                        d='M9 14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14C7 13.4477 7.44772 13 8 13C8.55228 13 9 13.4477 9 14Z'
                        fill='currentColor'
                      />
                      <path
                        d='M12 14C12 14.5523 11.5523 15 11 15C10.4477 15 10 14.5523 10 14C10 13.4477 10.4477 13 11 13C11.5523 13 12 13.4477 12 14Z'
                        fill='currentColor'
                      />
                    </svg>
                  </a>
                  <a href={`/${user?.role}/${props.parent}/notifications`} className='nots'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M19.5 8C20.163 8 20.7989 7.73661 21.2678 7.26777C21.7366 6.79893 22 6.16304 22 5.5C22 4.83696 21.7366 4.20107 21.2678 3.73223C20.7989 3.26339 20.163 3 19.5 3C18.837 3 18.2011 3.26339 17.7322 3.73223C17.2634 4.20107 17 4.83696 17 5.5C17 6.16304 17.2634 6.79893 17.7322 7.26777C18.2011 7.73661 18.837 8 19.5 8Z'
                        fill='currentColor'
                      />
                      <path
                        d='M19.5 8C20.163 8 20.7989 7.73661 21.2678 7.26777C21.7366 6.79893 22 6.16304 22 5.5C22 4.83696 21.7366 4.20107 21.2678 3.73223C20.7989 3.26339 20.163 3 19.5 3C18.837 3 18.2011 3.26339 17.7322 3.73223C17.2634 4.20107 17 4.83696 17 5.5C17 6.16304 17.2634 6.79893 17.7322 7.26777C18.2011 7.73661 18.837 8 19.5 8Z'
                        fill='currentColor'
                      />
                      <path
                        d='M20.72 9.31C20.02 9.53 19.25 9.57 18.45 9.37C17.7841 9.19202 17.176 8.84405 16.6851 8.3602C16.1942 7.87635 15.8375 7.27324 15.65 6.61C15.47 5.96 15.46 5.32 15.57 4.74C15.7 4.1 15.25 3.5 14.61 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V10.26C22 9.6 21.36 9.1 20.72 9.31ZM15.52 11.15L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.377 9.463 6.27907 9.28173 6.2567 9.08415C6.23433 8.88657 6.28927 8.68799 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L14.58 9.97C14.9 9.71 15.38 9.76 15.63 10.09C15.89 10.41 15.84 10.89 15.52 11.15Z'
                        fill='currentColor'
                      />
                    </svg>
                  </a>
                </Fragment>
              )}
            </div>

            {/* END END END date , notification , message */}
          </nav>
        </header>

        <Outlet />
      </div>
    </Fragment>
  )
}

export default SecondLayout
