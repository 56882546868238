// ** React Imports
import { createContext, useEffect, useState, ReactNode, useContext } from 'react'

// ** Next Import

// ** Axios

// ** Config

// ** Types
import { AuthValuesType, LoginParams, ErrCallbackType, UserDataType } from './types'
import { API_URL, request } from '../configs/request'
import auth from '../configs/auth'
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom'
import { QueryCache } from '@tanstack/react-query'
import { useCustomMutation } from '../hooks/useCustomMutation'
import useFcmToken from '../hooks/useFcmToken'
import { getMessaging, onMessage } from 'firebase/messaging'
import { firebaseApp } from '../configs/firebase'
import toast from 'react-hot-toast'
import { RandomContext } from '../providers/RandomProvider'
import NotificationToast from '../components/notification-toast'
import { useTranslation } from 'react-i18next'

const queryCache = new QueryCache({
  onError: error => {
    console.log(error)
  },
  onSuccess: data => {
    console.log(data)
  },
  onSettled: (data, error) => {
    console.log(data, error)
  }
})

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  refresh: () => Promise.resolve(),
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  useFcmToken()

  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)
  const { setFirebaseRandom } = useContext(RandomContext)
  const { i18n } = useTranslation()

  useEffect(() => {
    const lang = localStorage.getItem('lang')
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [])

  // ** Hooks
  const navigate = useNavigate()
  const match = useMatch('/sign-in')
  let [searchParams] = useSearchParams()
  const { mutate } = useCustomMutation('/auth/refresh', 'POST')
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const messaging = getMessaging(firebaseApp)
      const unsubscribe = onMessage(messaging, payload => {
        console.log('Foreground push notification received:', payload)
        toast.custom(t => (
          <NotificationToast t={t} body={payload?.notification?.body} title={payload?.notification?.title} />
        ))
        setFirebaseRandom(Math.random())
        // Handle the received push notification while the app is in the foreground
        // You can display a notification or update the UI based on the payload
      })

      return () => {
        unsubscribe() // Unsubscribe from the onMessage event
      }
    }
  }, [])

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      const refreshToken = await window.localStorage.getItem('refreshToken')

      if (!!refreshToken) {
        mutate(
          {
            refresh_token: localStorage.getItem('refreshToken')
          },
          {
            onSuccess: async data => {
              await localStorage.setItem('accessToken', data?.data?.access_token)
              await localStorage.setItem('userData', data?.data?.user)
              setUser(data?.data?.user)
            },
            onError: () => {
              handleLogout()
            }
          }
        )
      } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userData')
        localStorage.removeItem('refreshToken')
        setUser(null)
        setLoading(false)
        if (!match) {
          navigate('/sign-in')
        }
      }
      setLoading(false)
    }

    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogin = (params: LoginParams, errorCallback?: ErrCallbackType) => {
    // axios
    //   .post(authConfig.loginEndpoint, params)
    request({
      url: API_URL + auth.loginEndpoint,
      method: 'post',
      data: params
    })
      .then(async response => {
        localStorage.setItem(auth.storageTokenKeyName, response.data.access_token)
        const returnUrl = searchParams.get('returnUrl')
        setUser({ ...response.data.user })
        window.localStorage.setItem('permissions', JSON.stringify(response.data.permissions))
        window.localStorage.setItem('userData', JSON.stringify(response.data.user))
        const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
        navigate(redirectURL as string)
      })

      .catch(err => {
        if (errorCallback) errorCallback(err)
      })
  }

  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem(auth.storageTokenKeyName)
    navigate('/sign-in')
    queryCache.clear()
  }

  const values = {
    user,
    refresh: () =>
      mutate(
        {
          refresh_token: localStorage.getItem('refreshToken')
        },
        {
          onSuccess: async data => {
            await localStorage.setItem('accessToken', data?.data?.access_token)
            await localStorage.setItem('userData', data?.data?.user)
            setUser(data?.data?.user)
          },
          onError: () => {
            handleLogout()
          }
        }
      ),
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
