import { useTranslation } from 'react-i18next'
import Form from '../../../components/form'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import CustomInput from '../../../components/form/custom-input'
import CustomPasswordInput from '../../../components/form/custom-password-input'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import auth from '../../../configs/auth'
const validationSchema = Yup.object().shape({
  password: Yup.string().required('This field is required'),
  phone: Yup.string().required('This field is required')
})
const initialValues = {
  phone: '',
  password: '',
  role: ['student', 'teacher']
}
export const Login3 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setUser } = useContext(AuthContext)
  return (
    <>
      {/*  LOGIN	*/}
      <section className='login'>
        <div className='login__wrap'>
          <div className='login__logo'>
            <img src={require('../../../assets/img/logo.png')} alt='Onschool' />
          </div>
          <Form
            url='auth/login'
            initialValues={initialValues}
            validationSchema={validationSchema}
            method='post'
            onSuccess={async response => {
              await localStorage.setItem(auth.storageTokenKeyName, response.data.access_token)
              await localStorage.setItem('refreshToken', response.data.refresh_token)
              if (response?.message === 'NEED_VERIFICATION') {
                await navigate('/code')
              } else {
                setUser({ ...response.data.user })
                await window.localStorage.setItem('userData', JSON.stringify(response.data.user))
                if (response.data.user?.role === 'student') {
                  await navigate('/student/home')
                } else {
                  await navigate('/teacher/home')
                  document.body.classList.add('teacher')
                }
              }
            }}
          >
            {({ form, handleFinish }) => {
              const {
                control,
                formState: { errors },
                handleSubmit
              } = form
              return (
                <div className='login-form login-form__centered'>
                  <div className='login-form__title'>{t('Login')}</div>
                  <CustomInput
                    control={control}
                    name='phone'
                    label='Phone'
                    placeholder='Write phone'
                    error={errors?.phone}
                  />
                  <CustomPasswordInput
                    control={control}
                    name='password'
                    label='Password'
                    placeholder='Write password'
                    error={errors?.password}
                  />
                  <Link
                    style={{
                      marginBottom: '1rem',
                      color: '#fff'
                    }}
                    to={'/forgot-password'}
                  >
                    {t('Forgot password? Reset password')}
                  </Link>
                  <br />
                  <br />
                  <Link
                    style={{
                      marginBottom: '1rem',
                      color: '#fff'
                    }}
                    to={'/sign-up'}
                  >
                    {t('No profile? Open a personal profile')}
                  </Link>
                  <div className='login-form__submit'>
                    <button onClick={handleSubmit(data => handleFinish(data))} className='btn btn-orange'>
                      {t('Login')}
                    </button>
                  </div>
                </div>
              )
            }}
          </Form>
        </div>
      </section>
    </>
  )
}
